
import { Component, Mixins } from "vue-property-decorator";
import { Route, Next } from "vue-router";
import { mapState } from "vuex";
import { EntityType, BaseModalType } from "@/models/schoolfinder.enums";
import SchoolMixin from "@/mixins/school.mixin";
import ScrollToTopMixin from "@/mixins/scroll-to-top.mixin";
import SchoolOverallLetterGrade from "@/components/Application/SchoolAcademicPerformance/Common/SchoolOverallLetterGrade.vue";
import CommonMixin from "@/mixins/common.mixin";
import { isNaN } from "lodash";
@Component({
  components: {
    "school-overall-letter-grade": SchoolOverallLetterGrade,
  },
  computed: { ...mapState("app", ["mobileView", "tabletView"]) },
})
export default class SchoolOverallPerformance extends Mixins(
  SchoolMixin,
  ScrollToTopMixin,
  CommonMixin
) {
  mobileView!: boolean;
  tabletView!: boolean;

  BaseModalType = BaseModalType;

  showItemDetail = false;
  EntityType = EntityType;

  modalHeaderText = "";
  modalMessage = "";
  videoSrc = "";
  selectedModalType = BaseModalType.None;

  beforeRouteUpdate(to: Route, from: Route, next: Next<ScrollToTopMixin>) {
    this.scrollToTop();
    next();
  }

  showModal(modalType: BaseModalType, header: string, content: string) {
    this.modalHeaderText = header;
    this.selectedModalType = modalType;

    switch (modalType) {
      case BaseModalType.Message:
        this.modalMessage = content;
        break;
      case BaseModalType.Video:
        this.videoSrc = content;
        break;
      default:
        break;
    }

    // this.showItemDetail = true;
  }

  onNoClick() {
    this.showItemDetail = false;
    this.selectedModalType = BaseModalType.None;
    this.modalHeaderText = "";
    this.modalMessage = "";
    this.videoSrc = "";
  }

  get selectedModalData() {
    return this.modalData.find(
      (item) => item.modalType === this.selectedModalType
    );
  }

  get modalData() {
    return [
      {
        modalType: BaseModalType.Video,
        headerText: "School Performance Scores",
        videoSrc: this.videoSrc,
      },
      {
        modalType: BaseModalType.Message,
        headerText: this.modalHeaderText,
        modalMessage: this.modalMessage,
      },
    ];
  }

  get componentDataFiltered() {
    let result;
    if (!this.schoolSystem) result = this.componentData;
    else
      result = this.componentData.filter(
        (item) => !item.showSpsCompareSchoolSystem
      );
    return result;
  }

  get yearFromEntity(){
    let result;
    if(this.schoolSystem)
    {
        result = this.overallPerformance.schoolSystemCurrentYear.letterGradeYearFormatted;
    }
      else{
        result = this.overallPerformance.schoolCurrentYear.letterGradeYearFormatted;
      }
    return result;
  }

  get letterGradeContent(){
    let result;
    if(this.schoolSystem)
    {
      result = "The district performance score summarizes how well a school system is preparing all of its students for the next level of study. Several components are included in district performance scores. For elementary school students, this score is based on students’ mastery of key content for their grade level, their growth from the prior year, and their successful transition from 8th grade into 9th grade. For high school students, this score also measures graduation rates and how well schools are preparing students for college and a career.";
    }
    else{
      result = "The school performance score summarizes how well a school is preparing all of its students for the next level of study. For elementary schools, this score is based on students’ mastery of key content for their grade level, their growth from the prior year, and their successful transition into 9th grade for schools with 8th grade students. For high schools, this score also measures graduation rates and how well schools are preparing students for college and a career. Schools that are K-2 configurations have accountability data based on a paired school, designated by its district.";
    }
    return result;
  }

  get componentData() {
    return [
      {
        id: 1,
        header: this.overallLetterGradeHeader,
        showLetterGrade: true,
      },
      {
        id: 2,
        header: "How were the overall letter grades scored in " + this.yearFromEntity + "?",
        spsScore: true,
        instruction: this.letterGradeContent,
        videoSrc: "https://www.youtube.com/embed/rlSG0gCP0Y4",
        criteria: [
          { id: 1, value: "A: 90–150" },
          { id: 2, value: "B: 75–89.9" },
          { id: 3, value: "C: 60–74.9" },
          { id: 4, value: "D: 50–59.9" },
          { id: 5, value: "F: 0–49.9" },
        ],
        modalMessage: `Beginning in the 2017-18 school year, Louisiana’s accountability system raised expectations for what it means to be an “A” school. During this transition, the minimum score for what is required to earn an A, B, and C is adjusted.<br>
If a turnaround operator takes over an entire school that was labeled “F” in the previous school year, then the school’s grade is reported as “T” for the first two years.`,
      },
      {
        id: 3,
        header: this.schoolPerformanceOverTimeHeader,
        showSpsComparePrevious: true,
        modalMessage: "The calculation of School Performance Scores shifted in 2017-2018 in two new important ways. 1) The bar for “A” schools has been raised. Schools receive fewer points for students performing at the “Basic” level. 2) 25 percent of a school's rating (12.5% for high schools) comes from the new “Student Progress” index measuring how well students are progressing towards “Mastery” throughout the school year, regardless of where they started. <br><br> This measure values the individual progress of every student, and not just their performance at the end of the year. The " + this.yearFromEntity + " school performance scores also reflect the new alternative school rating formula. Prior to 2018-2019, alternative schools were evaluated according to the same formula as all other schools. <br><br> No letter grades were produced in 2019-2020 or 2020-2021 due to Covid-19.",
      },
      {
        id: 4,
        header:
          "How does this school’s score compare to its school system’s score in "+ this.yearFromEntity + "?",
        showSpsCompareSchoolSystem: true,
      },
    ];
  }

  get overallLetterGradeHeader() {
    let result;
    switch (this.entityType) {
      case EntityType.School:
        result = "What was this school's overall letter grade in "+ this.yearFromEntity +"?";
        break;
      case EntityType.SchoolSystem:
        result = "What is this school system's overall letter grade?";
        break;
      default:
        break;
    }
    return result;
  }

  get schoolPerformanceOverTimeHeader() {
    let result;
    switch (this.entityType) {
      case EntityType.School:
        result = "How has the school performed over time?";
        break;
      case EntityType.SchoolSystem:
        result = "How has the school system performed over time?";
        break;
      default:
        break;
    }
    return result;
  }

  get lastListItem() {
    return this.componentDataFiltered?.find((item) => item.spsScore)?.criteria
      ?.length;
  }

  get letterGradeScoredLabel() {
    let result;
    if (this.schoolSystem) result = "School System Performance Score";
    else result = "Letter Grade";
    return result;
  }

  get sidebarWidth(): string {
    let result = "";
    if (this.mobileView) {
      result = "100%";
    } else if (this.tabletView) result = "40%";
    return result;
  }

  get sidebarMessage() {
    const result = {
      comprehensiveInterventionMessage: 'Any school rated "D" or "F" in the state accountability system for three consecutive years or with an adjusted cohort graduation rate less than 67 percent in the most recent year is considered requiring comprehensive intervention. Schools demonstrating persistent subgroup or discipline challenges may also receive this label. Schools requiring comprehensive intervention will be required to submit a multi-year school improvement plan to the LDOE.',
      comprehensiveInterventionHeader: "Comprehensive Intervention Required",

      equityHonorRollMessage: "Schools designated as Opportunity Honorees are demonstrating excellence in serving economically disadvantaged students, students with disabilities, and/or English language learners. These schools also must not be persistently struggling with any specific groups of students or student behavior.",
      equityHonorRollHeader: "Opportunity Honoree",

      topGainsBadgeMessage: "Top Gains schools are those demonstrating exceptional student progress earning an for “A” for student progress, ensuring that students meet or exceed their learning goals each year. Top Gains schools must also not be persistently struggling with specific group of students or student behavior.",
      topGainsBadgeHeader: "Top Gains Badge Recipient",

      blueRibbonMessage: "The National Blue Ribbon Schools award affirms the hard work of students, educators, families, and communities in creating safe and welcoming schools where students master challenging content. Schools are nationally recognized based on their overall academic excellence or their progress in closing achievement gaps among student groups.",
      blueRibbonHeader: "National Blue Ribbon School",

      immersionCertMessage: "Schools earning the designation of World Language Immersion Certified School demonstrate high-quality teaching and learning practices that lead to biliteracy in English and the partner language. World Language Immersion Certified schools earn this distinction by successfully completing a rigorous, multistep research-based application and review process.",
      immersionCertHeader: "World Language Immersion Certified School",

      louisianaComebackCampusMessage: "A Comeback Campus is a school that is recognized for making significant academic gains following the Covid-19 pandemic. <br> For schools serving grades 3-8, a Comeback Campus is one where the percent Mastery and above in ELA and/or Math increased from 2019 to 2022, and the percent Unsatisfactory in ELA and/or Math decreased from 2019 to 2022.<br> For high schools, a Comeback Campus is one that, in 2022, achieved or exceeded its 2019 Mastery and above rates in ELA and Math, achieved or decreased its 2019 Unsatisfactory rates in ELA and Math, and achieved or exceeded its 2019 average ACT composite score.",
      louisianaComebackCampusHeader: "Louisiana Comeback Campus",
      
      purpleStarSchoolMessage: "<p>This award recognizes schools that show a major commitment to serving students and families connected to the United States Armed Forces.</p><p><b>To earn the recognition, schools shall engage in the following activities:</b></p><p><ul><li>Designate a staff member to serve as a military liaison and engage in the following:<ul><li>Serve as the primary point of contact for military connected students and families</li><li>Work to identify special considerations needed by military-connected students and families</li><li>Develop training to inform teachers and other school personnel of those special considerations</li><li>Create a dedicated page on its website featuring resources for military-connected students and families</li><li>Create a student-led transition program to provide peer support for military-connected students</li></ul></li></ul></p>",
      purpleStarSchoolHeader: "Purple Star School",
    };
    return result;
  }

  get showSchoolSystemProfile() {
    const districtCode = this.schoolAbout && this.schoolAbout.districtCode;
    const numeric = !isNaN(districtCode) && +districtCode;
    const valid = numeric && numeric > 0 && numeric < 70;
    return valid;
  }
}
